.table {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  border-radius: .5rem;

  & > {
    div{
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}